import React from 'react';
import {translate} from 'react-admin';

const statusStyle = {
  minWidth: '40px',
}

const StatusField = translate(({ record, translate }) => (
    <div style={statusStyle}>
        {
            record.status === "stocking_out" ? translate('resources.stockoutrequests.tab.stocking_out') : 
            record.status === "handling" ? translate('resources.stockoutrequests.tab.handling') : 
            record.status === "stocked_out" ? translate('resources.stockoutrequests.tab.stocked_out') : translate('resources.stockoutrequests.tab.cancelled') 
        }
    </div>
));

StatusField.defaultProps = {
    source: 'status',
    addLabel: true,
};

export default StatusField;