import chineseMessages from './i18n/cn';

const messages = {
    en: () => import('./i18n/en.js').then(messages => messages.default),
};

export default locale => {
    if (locale === 'en') {
        return messages[locale]();
    }

    // Always fallback on chinese
    return chineseMessages;
};